import { Button, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../global/LoadingScreen";
import { job } from "../../../../data.js"
import Apply from "./Apply/Apply.jsx";

const JobBoard = () => {
    const [loading, setLoading] = useState(true);
    const [showApply, setShowApply] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null)

    useEffect(() => {
        setTimeout(() => {

            if (job.length > 0) {
                setLoading(false)
            }
        }, 3000)
    }, []);

    const handleClickApply = (job) => {
        setShowApply(true);
        setSelectedArea({title: job.title, description: job.description})
    };
      
  
    const handleClickBack = () => {
      setShowApply(false)
      setSelectedArea(null)
    }

    if (loading) {
        return <LoadingScreen />
    }

    if (showApply) {
        return <Apply jobs={job} handleClickBack={handleClickBack} job={selectedArea} setShowApply={setShowApply} />;
    }

    return(
        <Container className="my-5">
            <Row>
                <h1>Job Areas</h1>
            </Row>
            <Row className="w-75">
                <h4 className="fw-bolder">Our engineering team is building a next generation hardware-software codesigned distributed system to meet performance and energy-efficiency demands of future large scale data analytics. We have opportunities in multiple areas for senior software engineers to become key members of our product development team.</h4>
            </Row>
            <Row className="mb-4">
                <h4 className="fw-bolder">What we are looking for:</h4>
            </Row>
            {job.map((job, id) => (
                <div key={id} className="mt-2 mb-5">
                    <h3>{job.title}</h3>
                    <ul>
                    {job.description
                        .split('•')
                        .filter(part => part.trim() !== '')
                        .map((part, idx) => (
                            <li key={idx} className="my-2 fw-bold">{part}</li>
                        ))}
                    </ul>
                    <Button 
                        className="mt-1" 
                        variant="primary-100" 
                        onClick={() => handleClickApply(job)}
                    >
                        Apply
                    </Button>
                </div>
            ))}
        </Container>
    )
};

export default JobBoard;