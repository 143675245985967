export const job = [
    {
        id: '1',
        title: 'Position 1',
        description: '•	BS/MS in Computer Science or related technical field with 5+ years of relevant industry experience. •	Optional: PhD in Computer Science or related technical field with 2+ years of relevant industry experience. •	2+ years of production level experience in either Java, Scala or C++. •	Strong foundation in algorithms and data structures and their real-world applications. •	Strong foundation in distributed computer architectures and data bases. •	Strong experience with big data systems (e.g., Spark, Hadoop).'
    },
    {
        id: '2',
        title: 'Position 2',
        description: '•	BS/MS in Computer Science or related technical field with 5+ years of relevant industry experience. •	Optional: PhD in Computer Science or related technical field with 2+ years of relevant industry experience. •	2+ years of production level experience in either Java, Scala or C++. •	Strong foundation in database query compilation and optimization. •	Strong foundation in distributed query execution and scheduling. •	Experience with big data systems. (e.g., Spark, Hadoop).'
    },
    {
        id: '3',
        title: 'Position 3',
        description: '•	BS/MS in Computer Science or related technical field with 5+ years of relevant industry experience.  •	Optional: PhD in Computer Science or related technical field with 2+ years of relevant industry experience. •	2+ years of production level experience in either Java, Scala or C++. •	Strong foundation in algorithms and data structures and their real-world applications. •	Strong foundation in compilers and/or runtime systems. •  Experience with big data systems (e.g., Spark, Hadoop).'
    },
]