import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const ContactForm = () => {
    const [sent, setSent] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
      });

    const formattedBody = `
    Name: ${formData.firstName} ${formData.lastName},
    Email: ${formData.email},
    Subject: ${formData.subject},
    Message: ${formData.message}
    `;


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.Email.send({
            SecureToken : "50db7723-cc0d-4169-a478-862441c23ee9",
            To : 'haitham@silicon45.com',
            From : 'oap9820@gmail.com',
            Subject : 'New Contact Form Submission',
            Body : formattedBody
        }).then(
          message => alert(message)
        );
    };
    
    if (sent) {
        setTimeout(() => {
            setSent(false)
        }, 5000)
    }

    return (
        <div className="main-container">
            <Container fluid className="p-0 vh-100 w-100 position-relative overlay overflow-hidden">
                <Row>
                    <Col className="p-0">
                        <video loop autoPlay playsInline muted>
                            <source src="/videos/bgVid.mp4" type="video/mp4" />
                        </video>
                    </Col>
                </Row>
            </Container>
            {sent ? (
                <Container className="text-overlay mt-5">
                    <Row className="justify-content-center">
                        <Col sm={12} className="w-auto">
                            <h3 className="mb-0 text-light">Your message has been sent</h3>
                        </Col>
                    </Row>
                </Container>
            )
            : (
                <Container fluid className="contact-overlay mt-5 position-absolute top-0 start-0">
                    <Row className="justify-content-center">
                        <h1 className="mt-5 w-auto text-light">Contact Us</h1>
                    </Row>
                    <Row className="justify-content-center">
                        <Form className="w-auto">
                            <Row>
                                <Col sm={12} className="mb-5 p-5 bg-info rounded-5 shadow">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-1">
                                            <p className="mb-0">Email Address</p>
                                        </Form.Label>
                                        <Form.Control
                                            type="email" 
                                            name="email" 
                                            value={formData.email} 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="mb-1">
                                                    <p className="mb-0">First Name</p>
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    name="firstName"
                                                    value={formData.firstName} 
                                            onChange={handleChange} 
                                                    required 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="mb-1">
                                                    <p className="mb-0">Last Name</p>
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    name="lastName"
                                                    value={formData.lastName} 
                                                    onChange={handleChange} 
                                                    required 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-1">
                                            <p className="mb-0">Subject</p>
                                        </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="subject"
                                            value={formData.subject} 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-1">
                                            <p className="mb-0">Message</p>
                                        </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={4} 
                                            name="message"
                                            value={formData.message} 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </Form.Group>
                                    <Button 
                                        variant="primary-100" 
                                        type="submit" 
                                        onClick={handleSubmit}
                                        disabled={
                                            !formData.email ||
                                            !formData.firstName ||
                                            !formData.lastName ||
                                            !formData.subject ||
                                            !formData.message
                                        }
                                    >
                                    <p className="mb-0 text-white">Submit</p>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Container>

            )}
            <div className="h-100 w-100 top-0 start-0 opacity-50 bg-dark overlay overflow-hidden" />
        </div>
    )
};

export default ContactForm;