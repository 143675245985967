import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';



const Apply = ({ jobs, handleClickBack, job, setShowApply }) => {
    const [applied, setApplied] = useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        file: null,
    });

    const formattedBody = `
    Name: ${formData.firstName} ${formData.lastName},
    Email: ${formData.email},
    Job Area: ${job.title}
    `;

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'file') {
            const selectedFile = e.target.files[0];
        setFormData({
          ...formData,
          file: selectedFile,
        });
        } else {
            setFormData({
              ...formData,
              [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reader = new FileReader();

        reader.onload = function (event) {
            const attachment = {
                name: formData.file.name,
                data: event.target.result.split(',')[1]
            }

            const emailData = {
                SecureToken: "50db7723-cc0d-4169-a478-862441c23ee9",
                To: 'haitham@silicon45.com',
                From: 'oap9820@gmail.com',
                Subject: `Silicon 45 ${job.title} Applicant`,
                Body: formattedBody,
                    Attachments: [attachment]
            };

            window.Email.send(emailData).then(
              message => alert(message)
            );
            setApplied(true)
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                file: null,
              });
        };

        if (formData.file) {
            reader.readAsDataURL(formData.file)
        }
    }
    
    if (applied) {
        setTimeout(() => {
            setApplied(false)
            setShowApply(false)
        }, 5000)
    }

    return (
        <>
            {applied ? (
                <Container className="contact-overlay mt-5">
                    <Row className="justify-content-center">
                        <Col sm={12} className="w-auto">
                            <Row className="justify-content-center">
                                <h3 className="w-auto">
                                    We appreciate your interest in {job.title}.
                                </h3>
                            </Row>
                            <Row className="justify-content-center">
                                <h3 className="mb-0 w-auto">
                                    Thank you for applying!
                                </h3>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container className="my-4 vh-100">
                    <Row className="justify-content-start">
                        <Button onClick={handleClickBack}>
                            <Row>
                                <Col sm={3} className="m-0 p-0 w-auto">
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Col>
                                <Col sm={9} className="w-auto">
                                    <p>
                                        Back to Job Board
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </Row>
                    <Form >
                        <Row>
                            <Col sm={6} className="pe-5">
                                <Row className="mb-1 ps-2 justify-content-start">
                                    <h1 className="w-auto">
                                        {job.title}
                                    </h1>
                                </Row>
                                <ul>
                                {job.description
                                    .split('-')
                                    .filter(part => part.trim() !== '')
                                    .map((part, idx) => (
                                        <li key={idx} className="my-2 fw-bold">{part}</li>
                                    ))}
                                </ul>
                            </Col>
                            <Col sm={6} className="p-5 bg-info rounded-5 shadow">
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1">
                                                <h5 className="mb-1">First Name</h5>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="firstName"
                                                value={formData.firstName} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1">
                                                <h5 className="mb-1">Last Name</h5>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="lastName"
                                                value={formData.lastName} 
                                                onChange={handleChange}
                                                required 
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">
                                        <h5 className="mb-1">Email</h5>
                                    </Form.Label>
                                    <Form.Control 
                                        type="email"
                                        name="email"
                                        value={formData.email} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </Form.Group>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className="mb-1">
                                        <h5 className="mb-1">Upload Resume</h5>
                                    </Form.Label>
                                    <Form.Control 
                                        type="file"
                                        name="file"
                                        onChange={handleChange}
                                        required 
                                    />
                                </Form.Group>
                                <Button 
                                    variant="primary-100" 
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={
                                        !formData.email ||
                                        !formData.firstName ||
                                        !formData.lastName ||
                                        !formData.file
                                    }
                                >
                                <p className="mb-0 text-white">Submit</p>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            )}
        </>
    )
};

export default Apply